import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'
import Shape from '../svg/shape-header.svg'

import { useWindowSize } from '../helpers/useWindowSize'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const ReviewDisplay = loadable(() => import('./ReviewDisplay'))
const SchemaHotel = loadable(() => import('./Schema/Hotel'))
const Buttons = loadable(() => import('./Buttons'))
const Content = loadable(() => import('./HeadingContent'))
const BookingSearch = loadable(() => import('./BookingSearch'))

const PageBuilderHeading = ({ block, location }) => {
  const {
    title,
    reducedSize,
    headerHeight,
    subtitle,
    hideSubtitle,
    reviews,
    text,
    buttons,
    bgImage,
    showBookingSearch,
    youtubeUrl,
    youtubeUrlMobile,
  } = block
  const windowSize = useWindowSize()
  const isMobile = windowSize.width <= 768

  const mobileVideo = youtubeUrlMobile || youtubeUrl

  const videoUrl = isMobile ? youtubeUrlMobile : mobileVideo

  let mobileHeight
  let desktopHeight

  switch (headerHeight) {
    case 'Tall':
      mobileHeight = 'h-heading-m-tall'
      desktopHeight = 'md:h-heading-d-tall'
      break
    case 'Short':
      mobileHeight = 'h-heading-m-short'
      desktopHeight = 'md:h-heading-d-short'
      break
    default:
      mobileHeight = 'h-heading-m'
      desktopHeight = 'md:h-heading-d'
  }

  if (showBookingSearch === true) {
    mobileHeight = 'min-h-[800px]'
  }

  const headingImageShaped = useSanityImageShape(bgImage)

  return (
    <header
      className={`relative w-full ${mobileHeight} ${desktopHeight} flex items-end`}
    >
      <div className="absolute inset-0 w-full h-full overflow-hidden text-gray-100">
        {youtubeUrl && (
          <div className="video-background">
            <div className="h-full ratio-16x9">
              <div className="inner">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={videoUrl}
                  config={{
                    youtube: {
                      playerVars: {
                        autoplay: 1,
                        controls: 0,
                        modestbranding: 1,
                        playsinline: 1,
                      },
                    },
                  }}
                  playing="true"
                  loop="true"
                  muted="true"
                  volume="0"
                  playsInline="true"
                  controls="false"
                />
              </div>
            </div>
          </div>
        )}
        {!youtubeUrl && headingImageShaped && (
          <SanityImage
            {...headingImageShaped}
            className="object-cover w-full h-full border-b"
            width={isMobile ? 500 : 1920}
            height={isMobile ? 520 : 1080}
            alt={`${title} - header`}
            loading="eager"
            preload
          />
        )}
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-35" />
        <Shape className="absolute bottom-0 z-10 w-full h-3 md:inset-x-0 min-w-screen-xs md:min-w-screen-lg" />
      </div>
      <div
        className={`container grid grid-cols-12 z-10 pt-8 md:pt-16 ${
          reducedSize ? 'pb-16 md:pb-40' : 'pb-16 md:pb-24'
        }`}
      >
        <div className="col-span-12 xl:col-span-10 xl:col-start-2">
          {reviews === true && (
            <ReviewDisplay textClasses="text-white text-sm" />
          )}
          <Content
            reducedSize={reducedSize}
            title={title}
            subtitle={subtitle}
            hideSubtitle={hideSubtitle}
            text={text}
          />
          
          {buttons?.length > 0 && <Buttons buttons={buttons} />}

          {showBookingSearch === true && 
            <div className="-mx-4 md:mx-0">
              <BookingSearch />
            </div>
          }
        </div>
      </div>
      {location.pathname === '/hotel/' && <SchemaHotel />}
    </header>
  )
}

PageBuilderHeading.defaultProps = {
  block: ``,
  location: {},
}

export default PageBuilderHeading
